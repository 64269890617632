<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <!-- <Button type="error" size="small" ghost @click="onDelete(row.id)">删除</Button> -->
                        <Button type="success" size="small" ghost @click="onManagerPermission(row)">权限管理</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqGroupList, reqSaveGroup } from '@/lib/request/auth';

export default {
    name: 'sysManagerRoleList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'groupname' }],
            dataColumn: [
                { key: 'id', title: 'ID', minWidth: 100, tooltip: true },
                { key: 'groupname', title: '用户组名', minWidth: 150, tooltip: true },
                { key: 'listorder', title: '排序', minWidth: 80 },
                // { key: 'createtime', title: '创建时间', minWidth: 120 },
                { slot: 'manager', title: '操作', minWidth: 130 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        getList() {
            this.ajaxLoading = true;
            reqGroupList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(res => {
                    this.ajaxLoading = false;
                });
        },
        //新建/编辑
        onEdit(info = {}) {
            let params = {};
            if (hm.isNotNullObject(info)) {
                params = {
                    id: info.id,
                    groupname: info.groupname,
                    listorder: info.listorder,
                };
            } else {
                params = {
                    create: 1,
                };
            }
            this.goName('sysManagerRoleAdd', params);
        },
        //删除
        onDelete(id) {
            this.confirmDialog('确认删除吗').then(isOK => {
                if (isOK == 1) {
                    console.log('onDelete', id);
                }
            });
        },
        //权限管理
        onManagerPermission(info = {}) {
            this.goName('sysManagerPermission', {
                id: info.id,
                groupname: info.groupname,
                flagarr: info.flagarr,
            });
        },
    },
};
</script>
